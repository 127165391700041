import { graphql } from "gatsby"
import Layout, { IMetadata } from "../components/Layout"
import React from "react"
import Section from "../components/ui/Section/Section"
import { GatsbyImage } from "gatsby-plugin-image";
import Blocks from "../components/blocks/Blocks"
import * as styles from "./fallbeispiele.module.scss"

export default ({ data }) => {
    const fallbeispiel: any = data.fallbeispieleHJson

    const crumbs = [
        { label: "Home", url: "" },
        { label: "Fallbeispiele", url: "fallbeispiele" },
        { label: fallbeispiel.name, url: fallbeispiel.url },
    ];

        // Create metadata object
        const metaData: IMetadata = {};
        if (fallbeispiel.meta) { 
            if (fallbeispiel.meta.description) {
                 metaData.description = fallbeispiel.meta.description;
             }
             if (fallbeispiel.meta.title) {
                 metaData.title = fallbeispiel.meta.title;
             }
             if (fallbeispiel.meta.ogImage) {
                 metaData.ogImage = fallbeispiel.meta.ogImage.childImageSharp.gatsbyImageData.images.fallback.src;
             }
             if (fallbeispiel.meta.ogTitle) {
                 metaData.ogTitle = fallbeispiel.meta.ogTitle;
             }
             if (fallbeispiel.meta.ogDescription) {
                 metaData.ogDescription = fallbeispiel.meta.ogDescription;
             }
         }

    return (
        <Layout crumbs={crumbs} waveBgColor={"white"} metadatas={metaData}>
            <Section size="extraSmall"  className={styles.headlineWrapper}>
                <h1 className={styles.h1} dangerouslySetInnerHTML={{__html: fallbeispiel.name}}></h1>
                <p className="subtitle">{fallbeispiel.kurzbeschreibung}</p>
                
            </Section>

            <Section>
                <GatsbyImage image={{...fallbeispiel.hauptbild.src.childImageSharp.gatsbyImageData}} />
            </Section>

            <Blocks blocks={[
                {
                    type: "section",
                    options: {
                        size: "extraSmall",
                        hiddenOn: ["mobile", "tablet"],
                    },
                    anchorMenu: true,
                },
                {
                    type: "collapse",
                    options: {
                        size: "extraSmall"
                    },
                    title: "Aufgabe des Kunden",
                    text: fallbeispiel.aufgabe,
                },
                {
                    type: "collapse",
                    options: {
                        size: "extraSmall"
                    },
                    title: "Unsere Lösung",
                    text: fallbeispiel.loesung,
                },
                ...(fallbeispiel.blocks ? fallbeispiel.blocks : []),
            ]} />
        </Layout>
    );
}

export const fallbeispielQuery = graphql`query FallbeispielQuery($url: String!) {
  fallbeispieleHJson(url: {eq: $url}) {
    meta {
      title
      description
      ogTitle
      ogImage {
        childImageSharp {
          gatsbyImageData(width: 300, height: 300, layout: FIXED)
        }
      }
      ogDescription
    }
    url
    pdf
    name
    kurzbeschreibung
    loesung
    hauptbild {
      alt
      src {
        childImageSharp {
          gatsbyImageData(quality: 95, layout: FULL_WIDTH)
        }
      }
    }
    aufgabe
    blocks {
      ...BlocksFragment
    }
  }
}
`
